class StatisticsEntity {
  constructor(
    chatStatistics,
    companyStatistics,
    eventStatistics,
    futureEventStatistics,
    // likesStatistics,
    newsItemStatistics,
    pinboardStatistics,
    pollStatistics,
    projectStatistics,
    userStatistics,
    usersAccessedInLastWeek,
    usersAccessedInLastThirtyDays,
  )
  {
    this._chatStatistics = chatStatistics;
    this._companyStatistics = companyStatistics;
    this._eventStatistics = eventStatistics;
    this._futureEventStatistics = futureEventStatistics;
    // this._likesStatistics = likesStatistics;
    this._newsItemStatistics = newsItemStatistics;
    this._pinboardStatistics = pinboardStatistics;
    this._pollStatistics = pollStatistics;
    this._projectStatistics = projectStatistics;
    this._userStatistics = userStatistics;
    this._usersAccessedInLastWeek = usersAccessedInLastWeek;
    this._usersAccessedInLastThirtyDays = usersAccessedInLastThirtyDays;
  }

  get companies() {
    return this._companyStatistics
      ? {
          count: this._companyStatistics.totalCompanies
      }
      : null;
  }

  get chats() {
    return this._chatStatistics 
      ? {
          count: this._chatStatistics.totalChats
      }
      : null;
  }

  get events() {
    return this._eventStatistics
      ? {
          count: this._eventStatistics.totalItems,
          futureEventCount: this._futureEventStatistics.totalItems
      }
      : null
  }

  // get likes() {
  //   return this._likesStatistics
  //     ? {
  //       count: this._likesStatistics.totalLikes ?? 0
  //     }
  //     : null
  // }

  get news() {
    return this._newsItemStatistics
      ? {
          count: this._newsItemStatistics.totalItems
      }
      : null;
  }

  get pinboard() {
    return this._pinboardStatistics
      ? {
          requests: this._pinboardStatistics.totalRequests,
          offers: this._pinboardStatistics.totalOffers
      }
      : null;
  }

  get polls() {
    return this._pollStatistics
      ? {
        count: this._pollStatistics.totalPolls ?? 0,
        votePercentage: (this._pollStatistics.pollVotePercentage ?? 0.0).toFixed(2)
      }
      : null;
  }

  get projects() {
    return this._projectStatistics
      ? {
          count: this._projectStatistics.totalItems
      }
      : null;
  }

  get users() {
    return {
      count: this._userStatistics.totalUsers,
      activeCount: this._userStatistics.totalActiveUsers,
      newUsersInLast30days: this._userStatistics.usersAddedLastMonth,
      usersAccessedInLastWeek: this._usersAccessedInLastWeek.totalActiveUsers,
      usersAccessedInLastThirtyDays: this._usersAccessedInLastThirtyDays.totalActiveUsers,
    };
  }
}

export default StatisticsEntity;