import { getRequest, postRequest } from '..';

const NEWS_ITEM_STATISTICS_TYPE = 0;
const PROJECT_ITEM_STATISTICS_TYPE = 1;
const EVENT_STATISTICS_TYPE = 2;

export const getChatStatistics = () => getRequest('/statistics/chats');
export const getCompanyStatistics = () => getRequest('/statistics/company');
export const getEventStatistics = () => getRequest(`/statistics/${EVENT_STATISTICS_TYPE}`);
export const getFutureEventStatistics = () => getRequest('/statistics/events/future');
export const getLikesStatistics = () => getRequest(`/statistics/likes`);
export const getNewsItemStatistics = () => getRequest(`/statistics/${NEWS_ITEM_STATISTICS_TYPE}`);
export const getPinboardStatistics = () => getRequest('/statistics/pinboarditems');
export const getPollsStatistics = () => getRequest('/statistics/polls');
export const getProjectStatistics = () => getRequest(`/statistics/${PROJECT_ITEM_STATISTICS_TYPE}`);
export const getUserStatistics = () => getRequest('/statistics/users');
export const getUsersAccessedCount = (startDate, endDate) => postRequest(
  '/statistics/users/accessed', {
  startDate,
  endDate
});