import StatisticsEntity from '@/domain/entities/statistics-entity';

import { moduleIsActive } from '../../helpers/module';

import { 
  getChatStatistics,
  getCompanyStatistics,
  getEventStatistics,
  getFutureEventStatistics, 
  getLikesStatistics,
  getNewsItemStatistics,
  getPinboardStatistics,
  getProjectStatistics,
  getPollsStatistics,
  getUserStatistics,
  getUsersAccessedCount,
} from '@/api/providers/statistics';

class StatisticsService {
  static async getStatisticsAsync() {
    const now = new Date();
    const oneWeekAgo = new Date(now - 7 * 24 * 60 * 60 * 1000);
    const thirtyDaysAgo = new Date(now - 30 * 24 * 60 * 60 * 1000);

    try {
      const [
        chatStatistics,
        companyStatistics,
        eventStatistics,
        futureEventStatistics,
        //likesStatistics,
        newsItemStatistics,
        pinboardStatistics,
        pollStatistics,
        projectStatistics,
        userStatistics,
        usersAccessedInLastWeek,
        usersAccessedInLastThirtyDays,
      ] = await Promise.all([
        this.#getChatModuleStatistics(),
        this.#getCompanyModuleStatistics(),
        this.#getEventModuleStatistics(),
        this.#getFutureEventModuleStatistics(),
        //this.#getLikesStatistics(),
        this.#getNewsItemModuleStatistics(),
        this.#getPinboardModuleStatistics(),
        this.#getPollModuleStatistics(),
        this.#getProjectModuleStatistics(),
        getUserStatistics(),
        getUsersAccessedCount(oneWeekAgo, now),
        getUsersAccessedCount(thirtyDaysAgo, now),
      ]);

      return new StatisticsEntity(
        chatStatistics,
        companyStatistics,
        eventStatistics,
        futureEventStatistics,
        //likesStatistics,
        newsItemStatistics,
        pinboardStatistics,
        pollStatistics,
        projectStatistics,
        userStatistics,
        usersAccessedInLastWeek,
        usersAccessedInLastThirtyDays,
      );
    }
    catch {
      return null;
    }
  }

  static #getChatModuleStatistics = async () => 
    await this.#getModuleStatistics("Chat", getChatStatistics);

  static #getCompanyModuleStatistics = async () => 
    await this.#getModuleStatistics("CompanyPage", getCompanyStatistics);

  static #getEventModuleStatistics = async () =>
    await this.#getModuleStatistics("Events", getEventStatistics);

  static #getFutureEventModuleStatistics = async () =>
    await this.#getModuleStatistics("Events", getFutureEventStatistics);

  static #getLikesStatistics = async () =>
    await getLikesStatistics();

  static #getNewsItemModuleStatistics = async () =>
    await this.#getModuleStatistics("News", getNewsItemStatistics);

  static #getPinboardModuleStatistics = async () =>
    await this.#getModuleStatistics("PinBoard", getPinboardStatistics);

  static #getPollModuleStatistics = async () =>
    await this.#getModuleStatistics("News", getPollsStatistics);

  static #getProjectModuleStatistics = async () =>
    await this.#getModuleStatistics("Projects", getProjectStatistics);
  
  static #getModuleStatistics = async (moduleName, callback) =>
    await moduleIsActive(moduleName)
      ? await callback()
      : null;
}

export default StatisticsService;