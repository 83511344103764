<template>
  <div class="grid grid4">
    <value-card 
      background-class="bg-info"
      label="Aantal gebruikers laatste week"
      :value="statistics.users?.usersAccessedInLastWeek ?? 0"
      font-awesome-icon-name="calendar"
    />

    <value-card 
      background-class="bg-secondary"
      label="Aantal gebruikers laatste maand"
      :value="statistics.users?.usersAccessedInLastThirtyDays ?? 0"
      font-awesome-icon-name="calendar"
    />

    <value-card 
      background-class="bg-success"
      label="Actieve gebruikers"
      :value="statistics.users?.activeCount ?? 0"
      font-awesome-icon-name="user-plus"
    />

    <value-card 
      background-class="bg-dark"
      label="Nieuw gebruikers laatste maand"
      :value="statistics.users?.newUsersInLast30days ?? 0"
      font-awesome-icon-name="user-plus"
    />

    <value-card 
      v-if="statistics.news"
      background-class="bg-success"
      label="Totaal aantal gebruikers"
      :value="statistics.users?.count ?? 0"
      font-awesome-icon-name="users"
    />

    <value-card 
      v-if="statistics.news"
      background-class="bg-gray-800"
      label="Aantal nieuwsberichten"
      :value="statistics.news?.count ?? 0"
      font-awesome-icon-name="newspaper"
    />

    <value-card 
      v-if="statistics.events"
      background-class="bg-warning"
      label="Aantal evenementen"
      :value="statistics.events?.count ?? 0"
      font-awesome-icon-name="clock"
    />

    <value-card 
      v-if="statistics.events"
      background-class="bg-gray-400"
      label="Aankomende evenementen"
      :value="statistics.events?.futureEventCount ?? 0"
      font-awesome-icon-name="clock"
    />

    <value-card
      v-if="statistics.companies"
      background-class="bg-gray-600"
      label="Aantal bedrijven"
      :value="statistics.companies?.count ?? 0"
      font-awesome-icon-name="building"
    />

    <value-card
      v-if="statistics.chats"
      background-class="bg-info"
      label="Aantal chats"
      :value="statistics.chats?.count ?? 0"
      font-awesome-icon-name="comment"
    />

    <value-card
      v-if="statistics.pinboard"
      background-class="bg-gray-400"
      label="Vraag /  Aanbod"
      :value="`${statistics.pinboard?.requests ?? 0} / ${statistics.pinboard?.offers ?? 0}`"
      font-awesome-icon-name="thumbtack"
    />

    <value-card 
      v-if="pollModuleIsActive && statistics.polls"
      background-class="bg-success"
      label="Aantal polls / gemiddeld stempercentage"
      :value="`${statistics.polls?.count ?? 0} / ${statistics.polls?.votePercentage ?? '0.00'}`"
      font-awesome-icon-name="poll-h"
      show-percentage-symbol
    />

    <!-- <value-card 
      v-if="statistics.likes"
      background-class="bg-info"
      label="Totaal Aantal likes"
      :value="`${statistics.likes.count ?? 0}`"
      font-awesome-icon-name="thumbs-up"
    /> -->
  </div>
</template>

<script>
import ValueCard from '@/components/Dashboard/ValueCard.vue';

import StatisticsService from '@/domain/services/statistics';

import { moduleIsActive } from '@/helpers/module';

export default {
  components: {
   ValueCard
  },

  data() {
    return {
      statistics: {},
      pollModuleIsActive: false
    };
  },

  async mounted() {
    this.statistics = await StatisticsService.getStatisticsAsync();

    this.pollModuleIsActive = await moduleIsActive("Poll");
  },
};
</script>

<style scoped>
.col-4 {
  flex-direction: column;
  display: flex;
  gap: 20px;
}
</style>